import React from "react";

function article_1() {
	return {
		date: "26 Dec 2023",
		title: "Top 15 Software Development Trends",
		link: "https://serokell.io/blog/software-development-trends",
		description:
			"As we step into 2024, the landscape of software development continues to evolve exponentially, driven by technological innovations and changing market needs. For businesses and developers alike, staying abreast of these trends is not just beneficial—it’s essential.",
		keywords: [],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "7 Feb 2024",
		title: "Top 15 Amazing Software Development Projects",
		link: "https://www.simplilearn.com/software-development-projects-article",
		description:
			"In software engineering, \"software development projects\" encapsulate a universe of innovation, problem-solving, and technological advancement. These projects are the backbone of our digital age, encompassing many endeavors ranging from creating complex applications to designing efficient systems.",
		style: ``,
		keywords: [],
		body: (
			<React.Fragment>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2];

export default myArticles;
