const INFO = {
    main: {
        title: "Samed H.",
        name: "Samed H.",
        email: "samedhaveri@gmail.com",
        logo: "../logo.png",
    },

    socials: {
        twitter: "https://twitter.com/",
        github: "https://github.com/SamedHaveri",
        linkedin: "https://linkedin.com/in/samed-haveri-581b8a224/",
        instagram: "https://www.instagram.com/samed_haveri25/",
        stackoverflow: "https://stackoverflow.com/",
        facebook: "https://facebook.com/",
    },

    homepage: {
        title: "Full-stack Web Developer, and a happy hiker.",
        description: () => {
            return <>I am a Fullstack Web developer with expertise in <a href="https://spring.io/">SpringBoot </a>
                and <a href="https://react.dev/">React</a>.
                I have experience in building scalable and reliable web applications using various frameworks and technologies.
                I enjoy solving complex problems and learning new skills, creating high-quality code that follows best practices and industry standards.
                Also on my free time i like to enjoy a calm walk in nature, just to get away from the screen a bit. </>
        },
    },

    //todo desc
    about: {
        title: "I’m Samed I live in Albania, where I design the future.",
        description:
            "I've worked on a variety of projects over the years and I'm proud of the progress I've made." +
            " Many of these projects are private business related. But there are some small interesting side projects you can peek on!" +
            " If you're interested in any of them please feel free to check out the code. If you wanna share some interesting thoughts you are welcome to contact me." +
            " I believe collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
    },

    articles: {
        title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
        description:
            "Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
    },

    projects: [
        {
            title: "EuroEtica",
            description:
                "Lead Software engineer on EuroEtica, an application focused on enabling companies with numerous employees" +
                " to generate anonymous tickets for reporting various concerns." +
                " Using Java Spring Boot for the backend and ReactJS for the frontend also leveraging Docker for the deployment process.",
            logo: "./icons/logo-euroetica.png",
            linkText: "View Project",
            link: "https://euroetica.com/",
        },

        {
            title: "GeniaHR",
            description:
                "Human resource application that helps ease the communication between employees and business." +
                " Giving ability to view paychecks, annual income tax statements. Requesting vacation, refunds... And many other features",
            logo: "./icons/logo-geniahr.png",
            linkText: "View Project",
            link: "https://geniahr.com",
        },

        {
            title: "BlackJack",
            description:
                "My first complex programming challenge. A Blackjack game made with Python using the Tkinter library. Something you don't usually use for this case." +
                " But it was still interesting to see that you can develop games even with a simple UI library",
            logo: "./icons/logo-blackjack.png",
            linkText: "View Source code",
            link: "https://github.com/SamedHaveri/BlackJack",
        },

        {
            title: "PhotoBackup",
            description:"The project used Kotlin for Android frontend and Room for client-side database operations," +
                " with a Java Spring Boot backend. It automates uploading phone media to a server and allows users to" +
                " access their media via a gallery view. Compression algorithms optimize the upload process for" +
                " bandwidth efficiency without compromising media quality.",
            logo: "./icons/logo-photobackup.png",
            linkText: "View Frontend code",
            link: "https://github.com/SamedHaveri/photo-backup-android",
            linkText2: "View Backend code",
            link2: "https://github.com/SamedHaveri/photo-backup-api"
        },

        {
            title: "Equity Fiscalization",
            description:
                "Equity Fiscalization in an application for B2B and B2C." +
                " Focusing on managing products, services and clients. Printing receipts," +
                " calculating taxes and declaring them using official AKSHI SOAP web services.",
            logo: "./icons/logo-fiscalization.png",
            linkText: "Dropped Project",
            link: null,
        },
    ],
};

export default INFO;
